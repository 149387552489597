/*
 *
 * Filename: login.css
 * Date: 2022-11-03
 * Description: Styles for login page.
 * Author: Philippe Leroux @ SKITSC
 *
 */
.logo--img{
    width: 100px;
    margin-top: 10vh;
    margin-bottom: 2vh;
}
.img{
    margin-bottom: 2vh;
    width: 100px;
}