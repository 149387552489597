/*
 *
 * Filename: main.css
 * Date: 2022-11-03
 * Description: Main css
 * Author: Philippe Leroux @ SKITSC
 *
 */
.app{  font-family: Lato, Helvetica, sans-serif;
}
html{
    background-color: #999;
}
body { margin: 0;} 
